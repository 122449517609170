import './About.css';
import { useState } from 'react';
import NavigationBar from '../navbar/NavigationBar';
import Container from 'react-bootstrap/Container';
import { ReactMarkdownWithPlugins } from "./../shared/ReactMarkdownWithPlugins.js";
import { Collapse, Button } from 'react-bootstrap';

function About() {
    const [aboutContent, setAboutContent] = useState(null);
    const [aboutAppOpen, setAboutAppOpen] = useState(false);
    const [changeLogOpen, setChangeLogOpen] = useState(false);

    fetch(process.env.REACT_APP_ABOUT_CONTENT_API_URL)
        .then(response => {
            if (response.ok) {
                response.text().then(message => setAboutContent(message));
            } else {
                response.text().then(message => {
                    if (message) {
                        console.log(message);
                    }
                });
            }
        })
        .catch(error => {
            console.log(error);
        });

    const changeLog = "" +
        ":ant: 2.0.0 (1/12/2025) Azure Cosmos DB - Pre-Trigger Added\n" +
        ":rocket: Many Other Changes Since Then\n" +
        ":baby_chick: 1.0.0 (12/27/2022) Web App Created";

    return (
        <>
            <div className="about-bg">
                <NavigationBar></NavigationBar>
                <Container style={{ marginTop: "1rem", marginBottom: "1rem" }}>
                    <div className='text-center' style={{ marginTop: "1rem", marginBottom: "1rem" }}>
                        <iframe
                            src="https://www.youtube.com/embed/buTr-t1vHVQ"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                            title="Inspiring & Uplifting Background Music"
                        />
                    </div>

                    <div className="text-center about-title-font">
                        <Button variant="outline-dark" onClick={() => setChangeLogOpen(!changeLogOpen)}>
                            Changelog
                        </Button>
                    </div>
                    <Collapse in={changeLogOpen}>
                        <div style={{
                            marginTop: "1rem",
                            background: "rgba(255, 255, 255, .45)",
                            padding: "20px",
                            borderRadius: "20px"
                        }}>
                            <ReactMarkdownWithPlugins content={changeLog}></ReactMarkdownWithPlugins>
                        </div>
                    </Collapse>

                    <div className="text-center about-title-font mt-3">
                        <Button variant="outline-dark" onClick={() => setAboutAppOpen(!aboutAppOpen)}>
                            About the app
                        </Button>
                    </div>
                    <Collapse in={aboutAppOpen}>
                        <div style={{
                            marginTop: "1rem",
                            background: "rgba(255, 255, 255, .45)",
                            padding: "20px",
                            borderRadius: "20px"
                        }}>
                            {
                                aboutContent &&
                                <ReactMarkdownWithPlugins content={aboutContent}></ReactMarkdownWithPlugins>
                            }
                            {
                                !aboutContent &&
                                <>
                                    <div className="text-center">
                                        <div className='text-muted'><em>Loading the content from backend...</em></div>
                                        <div className="lds-ellipsis">
                                            <div>
                                            </div>
                                            <div>
                                            </div>
                                            <div>
                                            </div>
                                            <div>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            }
                        </div>
                    </Collapse>
                </Container>

                <div style={{}}>
                    <div className='mb-2 text-center'>
                        <div className='mountain-sky-bg'>
                            <a href="https://www.freepik.com/free-photo/beautiful-shot-high-white-hilltops-mountains-covered-fog_7630706.htm#fromView=keyword&page=1&position=48&uuid=8340d278-1988-4f71-9433-55f92af43bbe&new_detail=true"><span className="text-muted small">Image by wirestock on Freepik</span></a>
                        </div>
                        <div className='branch-wheat-bg'>
                            <a href="https://www.freepik.com/free-photo/closeup-focused-shot-branch-wheat-with-bright-background_8281717.htm#fromView=keyword&page=1&position=1&uuid=8340d278-1988-4f71-9433-55f92af43bbe&new_detail=true"><span className="text-muted small">Image by wirestock on Freepik</span></a>
                        </div>
                    </div>
                </div>

                <div className='about-footer'>
                    <div className="text-muted">
                        <div style={{ color: "white" }}>
                            <a style={{ textDecoration: "none", color: "white" }} href="/">Home</a> | <a style={{ textDecoration: "none", color: "white" }} href="/blogs">Blogs</a> | <a style={{ textDecoration: "none", color: "white" }} href="/contact">Contact</a>
                        </div>
                        <div style={{ color: "white" }}>&copy; 2023 JustinKim.dev</div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default About;