import { useEffect } from 'react';
import ReactMarkdown from 'react-markdown';
import gfm from 'remark-gfm';
import breaks from 'remark-breaks';
import emoji from "remark-emoji";
import math from 'remark-math';
import katex from 'rehype-katex';
import hljs from 'highlight.js';
import 'highlight.js/styles/stackoverflow-dark.css';

function ReactMarkdownWithPlugins({ content }) {
    useEffect(() => {
        document.querySelectorAll('pre code').forEach((el) => {
            hljs.highlightElement(el);
        });
    });

    let newContent = content.replaceAll('<br>', '&#10;') // treat user written <br> as newline

    return (
        <ReactMarkdown
            children={newContent}
            linkTarget="_blank"
            remarkPlugins={[[gfm, { singleTilde: false }], breaks, emoji, math]}
            rehypePlugins={[katex]}>
        </ReactMarkdown>
    );
}

export { ReactMarkdownWithPlugins };