import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import Contact from './contact/Contact';
import Blogs from './blogs/Blogs';
import BlogCategory from './blogs/BlogCategory';
import BlogDetails from './blogs/BlogDetails';
import BlogNew from './blogs/BlogNew';
import Projects from './projects/Projects';
import OpenAI from './projects/openai/OpenAI';
import About from './about/About';

let alreadyVisited = sessionStorage.getItem('dmlzaXRlZA==');
if (!alreadyVisited) {
  fetch(process.env.REACT_APP_IP_API_URL).then(response => {
    if (response.ok) {
      response.text().then(value => {
        let userInfo = {}
        userInfo.currIp = value;

        const requestOptions = {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(userInfo)
        };

        fetch(process.env.REACT_APP_USER_API_URL, requestOptions)
          .then(response => {
            if (response.ok) {
              sessionStorage.setItem('dmlzaXRlZA==', true);
            }
          })
          .catch(error => {
            console.log(error);
          });
      });
    } else {
      console.log('ipLoc status: ' + response.status);
    }
  });
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<App />}>
      </Route>
      <Route path="/projects" element={<Projects />}>
      </Route>
      <Route path="/projects/openai" element={<OpenAI />}>
      </Route>
      <Route path="/blogs" element={<Blogs />}>
      </Route>
      <Route path="/blogs/new" element={<BlogNew />}>
      </Route>
      <Route path="/blogs/:category" element={<BlogCategory />}>
      </Route>
      <Route path="/blogs/:category/:slug" element={<BlogDetails />}>
      </Route>
      <Route path="/contact" element={<Contact />}>
      </Route>
      <Route path="/about" element={<About />}>
      </Route>
    </Routes>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
