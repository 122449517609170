import './Blogs.css';
import { useRef, useState } from 'react';
import { useNavigate } from "react-router-dom";
import NavigationBar from '../navbar/NavigationBar';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { getUserInfo } from "./../shared/UserUtility.js";
import Spinner from 'react-bootstrap/Spinner';
import Alert from 'react-bootstrap/Alert';
import { Preview } from "./../shared/Preview.js";
import { ConfirmPopup } from "./../shared/ConfirmPopup.js";
import { FormTextTips } from "./../shared/FormTextTips.js";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

function BlogNew() {
    const regex = /\s/g;

    const [apiAlert, setAPIAlert] = useState(false);
    const [apiAlertMessage, setAPIAlertMessage] = useState(null);
    const [apiSuccess, setAPISuccess] = useState(false);
    const [apiWaiting, setAPIWaiting] = useState(false);

    const [cancelFlagOn, setCancelFlagOn] = useState(false);
    const handleCancelClose = () => setCancelFlagOn(false);

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const formCategoryRef = useRef();
    const formContentRef = useRef();

    const formTitleRef = useRef();
    const [formTitleIsValid, setFormTitleIsValid] = useState(true);
    const handleFormTitleChange = (event) => {
        if (event.target.value === "") {
            setFormTitleIsValid(true);
        } else {
            setFormTitleIsValid(event.target.value.match("^[A-Z]{1,}"));
        }
    }

    const [formTags, setFormTags] = useState([{ formTag: "" }]);
    const handleFormTagChange = (i, e) => {
        let newFormTags = [...formTags];
        newFormTags[i][e.target.name] = e.target.value;
        setFormTags(newFormTags);
    }
    const addFormTag = () => {
        if (formTags && formTags.length > 4) {
            alert('Max tag size is 5');
        } else {
            setFormTags([...formTags, { formTag: "" }])
        }
    }
    const removeFormTag = (i) => {
        let newFormTags = [...formTags];
        newFormTags.splice(i, 1);
        setFormTags(newFormTags)
    }

    const handleSubmit = (event) => {
        event.preventDefault();

        const formData = new FormData(event.target),
            formDataObj = Object.fromEntries(formData.entries());

        // This is workaround...
        if (formDataObj.formTag) {
            delete formDataObj['formTag'];
        }

        if (formTags && formTags.length > 0) {
            formDataObj.formTags = formTags;
        }

        if (formTitleIsValid) {
            setAPIWaiting(true);

            getUserInfo().then(userInfo => {
                if (userInfo) {
                    formDataObj.formAuthor = userInfo;
                } else {
                    formDataObj.formAuthor = {};
                }

                const requestOptions = {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(formDataObj)
                };

                fetch(process.env.REACT_APP_BLOG_NEW_API_URL, requestOptions)
                    .then(response => {
                        if (response.ok) {
                            setAPISuccess(true);

                            let category = formCategoryRef.current.value;
                            let title = formTitleRef.current.value;
                            goToNewBlog(category, title);
                            // handleReset();
                        } else if (response.status === 406) {
                            response.text()
                                .then(respBody => {
                                    setAPIAlert(true);
                                    setAPIAlertMessage(respBody);
                                })
                                .catch(err => {
                                    setAPIAlertMessage(err.toString());
                                    setAPIAlert(true);
                                });
                        }
                        else {
                            setAPIAlert(true);
                        }
                        setAPIWaiting(false);
                    })
                    .catch(error => {
                        setAPIAlertMessage(error.toString());
                        setAPIAlert(true);
                        setAPIWaiting(false);
                    });
            });
        }
    }

    // On 12/19/2024, I decided not to stay on the same page upon save,
    // but rather navigate to newly created blog. Thus, below is no longer needed.
    // const handleReset = (event) => {
    //     event?.preventDefault();
    //     formCategoryRef.current.value = "";
    //     formTitleRef.current.value = "";
    //     formContentRef.current.value = "";
    //     setFormTags([{ formTag: "" }]);
    //     setAPIAlert(false);
    //     setAPIAlertMessage(null);
    // }

    const handleCancel = (event) => {
        event.preventDefault();
        setCancelFlagOn(true);
    }

    const navigate = useNavigate();
    const handleCancelProceed = (event) => {
        event.preventDefault();
        navigate('/blogs');
    }
    const goToNewBlog = (category, title) => {
        // regex defined at the top of the page, and replace method below creates a slug
        navigate(`/blogs/${category}/${title.replace(regex, "_")}`);
    }

    return (
        <>
            <div className="blog-new-bg">
                <NavigationBar></NavigationBar>

                <div className='text-center' style={{ marginTop: "1rem" }}>
                    <audio controls loop controlsList="nodownload" id="music">
                        <source src="/assets/audio/lounge.mp3" type="audio/mpeg" />
                        Your browser does not support the audio element.
                    </audio>
                </div>

                <Container style={{ marginTop: "1rem" }}>
                    {
                        apiAlert &&
                        <Alert variant="danger" onClose={() => setAPIAlert(false)} dismissible style={{ borderRadius: '20px' }}>
                            <Alert.Heading>Oh snap! Something's not right...</Alert.Heading>
                            {
                                apiAlert &&
                                <>
                                    {
                                        !apiAlertMessage &&
                                        <>
                                            <p>
                                                Please try again later.
                                            </p>
                                        </>
                                    }
                                    {
                                        apiAlertMessage &&
                                        <>
                                            <p>
                                                {apiAlertMessage}
                                            </p>
                                        </>
                                    }
                                </>
                            }
                        </Alert>
                    }
                    {
                        apiSuccess &&
                        <Alert variant="success" onClose={() => setAPISuccess(false)} dismissible style={{ borderRadius: '20px' }}>
                            <Alert.Heading>Success!</Alert.Heading>
                            <p>
                                Blog has been saved. Feel free to create another!
                            </p>
                        </Alert>
                    }
                    <div className="blog-new-form-box">
                        <Form onSubmit={handleSubmit}>
                            <Form.Group className="mb-3" controlId="formBasicCategory">
                                <Form.Label>Category</Form.Label>
                                {/* <Form.Select disabled> */}
                                <Form.Select
                                    required
                                    disabled={apiWaiting}
                                    name="formCategory"
                                    ref={formCategoryRef}>
                                    <option></option>
                                    <option value="ibmmq">IBM MQ</option>
                                    <option value="kafka">Kafka</option>
                                    <option value="supplychain">Supply Chain</option>
                                    <option value="devsecops">DevSecOps</option>
                                    <option value="frontend">Front-End</option>
                                    <option value="cloud">Cloud Computing</option>
                                    <option value="java">Java</option>
                                    <option value="ai">AI</option>
                                </Form.Select>
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="formBasicTitle">
                                <Form.Label>Title</Form.Label>
                                <Form.Control
                                    disabled={apiWaiting}
                                    required
                                    onChange={handleFormTitleChange}
                                    isInvalid={!formTitleIsValid}
                                    name="formTitle"
                                    ref={formTitleRef}
                                    type="text" />
                                <Form.Control.Feedback type="invalid">First letter must be a CAPITAL letter.</Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="formBasicContent">
                                <Row>
                                    <Form.Label>Content</Form.Label>
                                </Row>
                                <Row>
                                    <FormTextTips></FormTextTips>
                                </Row>

                                {/* Placing this tag under Row widens the text area compare to other text area */}
                                <Form.Control
                                    disabled={apiWaiting}
                                    name="formContent"
                                    ref={formContentRef}
                                    as="textarea" rows={10} />
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="formBasicTags">
                                <Form.Label>Tags</Form.Label>
                                {' '}
                                <Button
                                    variant="outline-secondary"
                                    size="sm"
                                    type="button"
                                    onClick={addFormTag}
                                    disabled={apiWaiting}>
                                    Add
                                </Button>
                                {
                                    formTags.map((element, index) => (
                                        <div key={index}>
                                            <Row>
                                                <Form.Text className="text-muted">Tag-{index + 1}</Form.Text>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <Form.Control
                                                        size="sm"
                                                        disabled={apiWaiting}
                                                        required
                                                        value={element.formTag || ""}
                                                        onChange={e => handleFormTagChange(index, e)}
                                                        name="formTag"
                                                        type="text" />
                                                </Col>
                                                <Col>
                                                    <Button
                                                        variant="outline-danger"
                                                        size="sm"
                                                        type="button"
                                                        onClick={removeFormTag.bind(this, index)}
                                                        disabled={apiWaiting}>
                                                        Remove
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </div>
                                    ))
                                }
                            </Form.Group>

                            <Button variant="danger" type="button" onClick={handleCancel} disabled={apiWaiting}>
                                Cancel
                            </Button>
                            {' '}
                            <Button variant="success" type="button" onClick={handleShow} disabled={apiWaiting}>
                                Preview
                            </Button>
                            {' '}
                            {
                                !apiWaiting &&
                                <Button variant="primary" type="submit">
                                    Submit
                                </Button>
                            }
                            {
                                apiWaiting &&
                                <Button variant="primary" disabled>
                                    <Spinner
                                        as="span"
                                        animation="grow"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    />
                                    Submitting...
                                </Button>
                            }
                        </Form>
                    </div>
                </Container>
            </div>
            {
                formContentRef.current
                &&
                <Preview preview={{ show: show, content: formContentRef.current.value }} handleCallBack={handleClose}></Preview>
            }
            {
                <ConfirmPopup
                    show={cancelFlagOn}
                    handleCallBack={handleCancelClose}
                    handleProceed={handleCancelProceed}
                    title="Cancel"
                    message="Are you sure? You can possibly lose your work, FYI."
                >
                </ConfirmPopup>
            }
        </>
    );
}

export default BlogNew;